.customeInvoice {
	margin-top: 18px;
}

.topTimeLines {
	margin-top: -24px !important;
	margin-bottom: 7rem !important;
}

.vertical-timeline-element-date {
	color: #1e1e2d !important;
}

.vertical-title-order {
    margin-top: -20px;
    padding-bottom: 31px;
    text-align: center;
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns:before {
        left: 50%;
        margin-left: -2px;
        top: 37px;
    }
}
