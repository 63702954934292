.rdrDefinedRangesWrapper {
  font-size: 10px !important;
  width: 30% !important;
  border-right: solid 1px #eff2f7;
  background: #fff;
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 70% !important;
}
.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
  width: 100% !important;
}
.rdrCalendarWrapper {
  color: #000000;
  font-size: 11px !important;
}
@media (max-width: 440px) {
  .rdrDateRangePickerWrapper {
    display: flex !important;
  }
  .rdrDefinedRangesWrapper {
    width: 25% !important;
  }
  .rdrCalendarWrapper {
    width: 75% !important;
  }
  .rdrInputRange {
    padding: 0px !important;
    display: block !important;
  }
  .rdrInputRangeInput {
    width: 95%;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122);
  }
}
@media (max-width: 440px) {
  .rdrDefinedRangesWrapper {
    width: 32% !important;
  }
  .rdrCalendarWrapper {
    width: 68% !important;
  }
}
@media (max-width: 991px) {
  .rdrInputRange {
    padding: 0px !important;
    display: block !important;
    text-align: center;
    margin-top: 10px;
  }
  .rdrInputRangeInput {
    width: 95%;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122);
  }
}
@media (max-width: 1024px) {
  .rdrDefinedRangesWrapper {
    width: 32% !important;
  }
  .rdrCalendarWrapper {
    width: 68% !important;
  }
}
