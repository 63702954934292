:root {
  --success: #4caf50;
  --danger: #e90110;
}

ul.breadcrumb.breadcrumb-transparent.breadcrumb-dot.font-weight-bold.p-0.my-2 {
  display:  !important;
}

ul.sticky-toolbar.nav.flex-column.pl-2.pr-2.pt-3.pb-3.mt-4 {
  display: ;
}

.borderGap {
  border-bottom: 1px dashed #e5eaee;
  margin-bottom: 20px;
  width: 100%;
}

.brand-logo img {
  width: 70px;
}

.f-width {
  width: 150px;
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 57px;
  }
}

@media (min-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 249px;
  }
}

.imgPreview {
  margin-top: 5px;
  width: 150px;
}

.imgPreview i {
  float: right;
  margin-bottom: 0px;
  font-size: 25px;
  cursor: pointer;
}

.imgPreview img {
  width: 145px;
}

.inputError {
  color: rgb(238, 66, 66);
}

.MuiInputBase-root.search-input {
  width: 85%;
}

input.MuiInputBase-input {
  margin-left: 10px;
  line-height: 15px;
}

.MuiAppBar-colorDefault {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(253 246 246 / 0%),
    0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.card.card-custom>.card-body {
  padding: 0.5rem 1.25rem;
}

.borderGap {
  border-bottom: 1px dashed #e5eaee;
  margin-bottom: 20px;
  width: 100%;
}

.site-name {
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 991.98px) {
  .subheader-enabled .content {
    padding-top: 15px;
  }
}

.aside-minimize .brand .brand-logo span {
  display: none;
}

.site_name_login {
  text-transform: uppercase;
  font-weight: 800;
  color: #2f3044;
  margin-top: -5px !important;
}

.login-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}

/* Design Dash Board */

.shipping-card {
  background-color: #4a7dff;
  padding-top: 30px;
  padding-bottom: 5px !important;
}

.shipping-card h2 {
  color: white;
  font-weight: 800;
  padding-top: 15px;
}

.second-color {
  background-color: #3699ff;
}

.shipping-card p {
  color: white;
  font-size: 12px;
}

.spacer {
  padding-left: 30px;
}

/* Design Dash Board */
/* custom form design */

.formHeight {
  height: 30px !important;
  background: #fff;
  font-size: 10px !important;
  font-weight: 400;
}

.css-1fhf3k1-control {
  height: 30px !important;
  min-height: 30px !important;
}

.form-input-white {
  background: #fff;
}

.formFont {
  font-size: 10px !important;
  margin-bottom: 0px !important;
}

button.saveButton {
  height: 29px;
  line-height: 10px;
  background-color: #789dfe !important;
  font-weight: 400;
  border-radius: 3px;
}

.cancelButton {
  background-color: #e5eaee !important;
  /* height: 29px; */
  line-height: 12px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px;
}

.formSelect {
  height: 30px;
  font-size: 10px !important;
  background: #f3f6f9;
  font-weight: 500;
  padding-top: 5px;
}

.chooseFileText {
  color: #b5b5c3;
}

.fileInput {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

input.fileInput,
span#inputGroupPrepend {
  height: 30px;
}

/* DashBoard Main Page Design Starts */

.dashboard__pb {
  padding-bottom: 30px;
}

.paid__widget {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  background: #f64e60;
  overflow: hidden;
  color: #ffff;
  padding: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget__right {
  padding-top: 10px;
}

.widget__right p {
  color: #ffff;
  font-weight: 500;
  font-size: 14px !important;
  padding-top: 7px;
}

.widget__right h4 {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.chart__box {
  box-shadow: 0px 2px 6px #2c28281c;
  border-radius: 10px;
  padding-bottom: 10%;
  overflow: hidden;
  height: 380px;
}

.chart__box p {
  padding-top: 10px;
  font-size: 11px !important;
  padding-left: 10px;
}

.chart__box h4 {
  font-size: 14px !important;
  padding-left: 10px;
  color: #172b4d;
}

.paid__widget.one {
  background-color: #6993ff;
}

.paid__widget.two {
  background-color: #1bc5bd;
}

.paid__widget.three {
  background-color: #ffa800;
}

.dough__main {
  padding-top: 30px;
}

.doug__left {
  float: left;
  width: 52%;
  float: left;
  width: 52%;
  padding-top: 25px;
}

.doug__right {
  float: left;
  width: 40%;
  padding-top: 25px;
}

.color__circle {
  display: inline-block;
}

.stat__1 {
  position: relative;
}

.stat__1::before {
  content: "";
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #feb969;
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #feb969;
}

.border__orange {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 10px;
  left: 0px;
  border: 3px solid #2dce98;
}

.border__blue {
  border: 3px solid #1f50b1;
}

.border__red {
  border: 3px solid #f53c56;
}

.dough__list {
  color: #4d4f5c;
  font-size: 11px;
  font-weight: 600;
  margin-left: 3px;
  font-family: "poppins";
}

.list__1 {
  margin-right: 25px;
}

.list__2 {
  margin-right: 15px;
}

.list__3 {
  margin-right: 70px;
}

.list__4 {
  margin-right: 67px;
}

.dough__num {
  font-weight: 500;
}

.widget__left img {
  width: 30px;
}

.widget__left h4 {
  font-size: 35px;
}

.widget__left.three img {
  width: 20px;
}

/* DashBoard Main Page Design Ends */

.form-control {
  height: calc(1.2em + 1.1rem + 1px) !important;
  padding: 0.65rem 1rem;
}

/* login page  */

.form-control:focus {
  color: #000000 !important;
}

.stat__2 {
  position: relative;
}

.stat__2::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #2dce98;
}

.stat__3 {
  position: relative;
}

.stat__3::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #1f50b1;
}

.stat__4 {
  position: relative;
}

.stat__4::before {
  content: "";
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 9px;
  left: -4px;
  border: 3px solid #f53c56;
}

a#kt_login_forgot {
  color: #3699ff !important;
  font-weight: 600;
}

button#kt_login_signin_submit {
  box-shadow: #00000029 !important;
  margin: auto;
}

.heading {
  color: #160637;
  font-size: 30px !important;
}

span.loginText {
  font-weight: 600;
}

a {
  color: #4174aa !important;
  font-size: 12px;
}

img.loginpageLogo {
  width: 180px;
}

.login.login-1 .login-aside {
  height: 100vh;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #eef1f5;
  box-shadow: 0px 1px 5px #00000010 !important;
}

h1.font-size-h1.heading {
  text-align: center;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

.heading {
  font-size: 22px !important;
}

.overlay__caption {
  background-color: #007bff;
  color: white;
  border-radius: 9px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s;
  position: relative;
  border-radius: 5px;
}

.overlay__caption:hover {
  color: #fff;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15); */
}

.overlay__caption:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #007bff;
  opacity: 0;
  transition: all 0.3s, opacity 0.2s;
  transform: scaleY(0);
  transform-origin: bottom;
}

.overlay__caption:hover:before {
  transform: scaleY(1);
  opacity: 0.25;
}

.login.login-1 .login-aside {
  width: 100%;
}

.loginForm input.form-control {
  background: #eef1f5 !important;
  height: 36px !important;
  border: 1px solid #95989a4d;
  box-shadow: 0px 1px 5px #00000029 !important;
}

.loginForm input#formBasicChecbox {
  box-shadow: 0px 3px 5px #00000021 !important;
  border: none !important;
}

.flex-row-fluid.d-flex.flex-column.position-relative.loginPage {
  max-width: 50% !important;
}

.login-aside.d-flex.flex-row-auto.loginBg {
  max-width: 50%;
}

input.form-control.loginFormInput.emailinput.h-auto.py-5.px-6.is-valid {
  width: 300px !important;
}

.login-signin {
  width: 400px;
}

img.loginpageLogo {
  margin: auto;
  display: block;
}

/* Table */

button.btn.approve {
  background: #eee5ff;
  color: #8950fc;
  border-color: #eee5ff;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2;
}

i.editIcon {
  background: #f3f6f9;
  padding: 8px;
  border-radius: 5px;
  color: #3699ff;
}

/* i.editIcon:hover {
  border: 2px solid #8950fc;
  padding: 8px;
} */

input.form-check-input.position-static {
  background: #f3f6f9;
}

.circulerBar {
  width: 39px;
}

.voyageTable td {
  color: #464e5f;
  font-size: 13px;
}

.voyageTable th {
  color: #b5b5c3;
  font-size: 12px;
  text-transform: uppercase;
}

h1.tableheading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
}

i.tableFilter {
  color: #3699ff;
}

.input-area-add .float-left {
  width: 80%;
}

.input-area-add .float-right {
  width: 20%;
}

.input-area-add input,
.input-area-add select {
  border-radius: 0px !important;
}

.btn-modal-close {
  position: absolute;
  right: -16px;
  padding-right: 20px;
  cursor: pointer;
  top: -10px;
  background: #ffffffed;
  border-radius: 20px;
  width: 36px;
  height: 36px;
  padding: 12px;
  box-shadow: 1px 2px 30px grey;
}

.btn-default {
  position: relative !important;
}

.btn-default i {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  bottom: 7px;
  font-size: 14px !important;
}

button.btn.btn-default {
  border: 1px solid #e5eaee;
}

.btn.btn-default i {
  color: #d9e0e6;
}

.btn-modal-close i {
  margin: -1px;
  margin-left: 1px;
  margin-top: -2px;
}

.input-area-add .btn-default,
.input-area-add .btn-default:hover {
  border-radius: 4px !important;
  /* background: white; */
  /* border-color: #ecf0f3; */
  /* border-left: 0px !important; */
  margin-left: -3px;
  background: #fff;
  border: 1px solid #e5eaee !important;
  height: 30px;
  background: #fff !important;
}

i.fa.fa-plus-circle {
  /* background: #fff !important; */
}

.tbl-survey {
  margin: 10px 0px 10px 0px;
}

.tbl-survey thead {
  background: white !important;
}

.tbl-survey th {
  background: #789dfe38;
}

/* basic form */

.css-1okebmr-indicatorSeparator {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.css-yk16xz-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-1pahdxg-control {
  background: #fff !important;
  height: 30px !important;
  min-height: 30px !important;
  border: 1px solid #e5eaee !important;
}

.css-g1d714-ValueContainer {
  padding: 3px !important;
  /* margin-top: -5px !important; */
}

.aside-menu .menu-nav>.menu-section {
  margin: 0px 0 0 0 !important;
  height: 25px !important;
}

.PrivateTabIndicator-colorPrimary-65 {
  background-color: #3699ff !important;
}

.aside-menu .menu-nav>.menu-item>.menu-link {
  padding: 5px 20px !important;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link {
  min-height: 30px !important;
}

.css-26l3qy-menu {
  padding: 0px !important;
}

.aside-menu .menu-nav>.menu-item>.menu-heading,
.aside-menu .menu-nav>.menu-item>.menu-link {
  min-height: 36px !important;
}

.aside .aside-menu .menu-nav {
  width: auto !important;
  /* width: 1800px; */
}

.css-1laa021-a11yText {
  padding: 0px !important;
}

.css-1wa3eu0-placeholder {
  top: 42% !important;
  font-size: 10px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  margin-top: -5px !important;
}

.css-1uccc91-singleValue {
  top: 35% !important;
  font-size: 10px !important;
}

.searchInput {
  background: #fff !important;
  height: 30px !important;
  border: 1px solid #e5eaee !important;
  box-shadow: none !important;
}

.searchInput i.flaticon-search {
  padding-left: 5px !important;
}

button.MuiButtonBase-root.MuiIconButton-root.searchPlaceholder {
  padding: 0px !important;
  padding-top: 2px !important;
}

input.MuiInputBase-input {
  margin-left: 10px;
}

.table.table-head-custom thead th,
.table.table-head-custom thead tr {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid #ecf0f3 !important;
  padding: 8px;
}

.user-list-table td,
th {
  border-bottom: 1px solid #ecf0f3 !important;
}

.table {
  margin-bottom: 2px !important;
}

.table.table-head-custom thead td {
  color: #464e5f;
  font-weight: 400 !important;
}

.table.table-head-custom thead th {
  color: #1e1e2d !important;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.modal-body {
  padding: 0.75rem;
  padding-top: 0px !important;
}

input[type="file"] {
  border: 1px solid #e5eaee;
  border-radius: 4px;
}

.MuiPaper-rounded {
  border-radius: 6px !important;
}

.custome-datepicker {
  position: relative;
}

i.fas.fa-calendar-alt {
  position: absolute;
  top: 29px;
  right: 20px !important;
  font-size: 10px !important;
}

.pagination-area {
  font-weight: 500;
  float: right !important;
  margin-right: 1.8rem;
}

.pagination {
  justify-items: flex-end !important;
  justify-content: flex-end !important;
}

.page-item.active .page-link {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff !important;
  z-index: 0;
  color: #abb5be;
}

input.date-picker,
input.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  border-radius: 4px;
  height: 30px;
  border: 1px solid #e5eaee;
  font-size: 10px !important;
  color: #464e5f;
  padding-left: 10px;
  position: relative !important;
}

.loginBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button#kt_login_signin_submit {
  width: 142px;
}

/* line chart */

.line-chartsection {
  border-radius: 12px;
}

.pointer {
  cursor: pointer;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.status-list {
  background: aliceblue;
  padding: 4px;
  margin-top: 30px;
}

.float-left.total {
  width: 21%;
}

.float-left.balance {
  width: 16%;
}

/* table form */

.row.mb-5.table-form {
  align-items: center;
}

/* dashboard table */

h1.sales-payment-heading {
  font-size: 20px;
  color: #212121;
}

table.table.table.dashboard-table th,
td {
  font-weight: 600;
  color: #212121 !important;
  text-transform: capitalize;
}

p.dashboard-table-text {
  font-weight: 600;
  background-color: #f3f6f9;
}

.btn.invoice-btn {
  border-color: #789dfe;
  background: #789dfe;
}

.btn.invoice-btn:hover {
  border-color: #789dfe !important;
  background: #789dfe !important;
}

button.btn.text-white.float-right.invoice-btn {
  padding: 5px 10px !important;
  border-radius: 4px !important;
}

p.show-value-text {
  color: #000000 !important;
}

.attachment-file input {
  /* color: #b5b5c3; */
  font-size: 12px !important;
}

::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 5px;
  /* color: #b5b5c3; */
}

/*  */

a._2pGos._3CDiP._2RWmX,
a._2pGos._3CDiP {
  background: #e5e5e5 !important;
  line-height: 10px !important;
  color: #464e5f !important;
  font-weight: 400;
  border-radius: 3px !important;
}

._hsN1w {
  line-height: 10px !important;
  background-color: #789dfe !important;
  border: 1px solid #789dfe !important;
  font-weight: 400;
  border-radius: 3px !important;
  color: #fff !important;
}

._3uApM {
  margin-top: -5px !important;
  margin-left: 25px;
}

h3 {
  font-size: 18px !important;
}

._1hzhf {
  font-size: 14px;
}

._2Jtxm._35Ago ._2kL0S {
  margin-left: -140px;
}

._2Jtxm {
  margin-left: -66px;
}

._1Lo2h {
  width: 109%;
}

/* Certificate create */

.card-header.certificate-cardheader {
  min-height: 0px !important;
}

.certificate-card-body {
  padding: 0rem 2.25rem !important;
}

.header.header-fixed {
  height: 50px !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 54px !important;
}

.custome-addnew-btn {
  height: 29px;
  padding: 5px;
}

/* item list start */

i.item-list-icon {
  font-size: 12px !important;
}

.itemtable td {
  font-weight: 400 !important;
}

i.editIcon.item-list-icon {
  padding: 5px;
}

button.item-list-btn {
  height: 33px;
  border-radius: 4px;
  background: #789dfe !important;
  border: 1px solid #789dfe !important;
}

/* item add */

button.item-add-btn {
  border: 1px solid #afafaf !important;
  background: #e4ebff !important;
}

button.item-add-save {
  padding-left: 35px;
  padding-right: 35px;
  background: #1bc5bd !important;
  border: #1bc5bd !important;
  border-radius: 4px !important;
}

table.table.table.item-add-table th,
tr,
td {
  border-top: none !important;
  border-bottom: none !important;
  font-weight: 400 !important;
}

.form-group label {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  color: #1e1e2d;
}

/* .input-group {
        width: 112% !important;
    } */
span.input-group-text {
  line-height: 10px;
}

.booking-btn {
  background-color: #007bff !important;
  line-height: 12px !important;
  border-radius: 3px;
}

.MuiPaper-elevation1 {
  box-shadow: none 0px !important;
}

.booking-list-btn {
  background: #fff2d8 !important;
  border: 1px solid #fff2d8 !important;
}

span#inputGroupPrepend {
  height: 30px !important;
  border-left: 0px !important;
}

.MuiPaper-root.searchInput.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none !important;
}

.certificate-add-btn.btn-sm {
  line-height: 1.15 !important;
}

.row.custom-modal {
  width: 1063px;
}

hr.hr-margin {
  margin-top: 8px !important;
}

/* userlist modal */

button.modal-button {
  border: none !important;
  background: none !important;
}

/* cerificate list */

button.certificate-done {
  background: #8ec7ff !important;
  border: #8ec7ff !important;
}

.certificate-due {
  background: #678db2 !important;
  border: #678db2 !important;
}

.certificate-lis-btn {
  padding: 3px 15px !important;
  border-radius: 2px !important;
  width: 75px !important;
  border: none !important;
}

.certificate-edit-icon {
  background: #e7f3fe !important;
  padding: 5px;
  border-radius: 2px;
  color: #3699ff;
  font-size: 11px;
}

.due-days h6 {
  font-size: 11px !important;
  text-transform: uppercase;
  position: absolute;
  top: 6px;
  left: 29px;
}

.due-days {
  position: relative;
}

p.certificate-list-text {
  width: 101px;
  font-size: 12px !important;
  font-weight: bold;
}

.certificate-due-0 {
  background: #ea673e !important;
}

.certificate-due-l-30 {
  background: #8ec7ff !important;
}

.certificate-due-30-60 {
  background: #678db2 !important;
}

.certificate-due-g-60 {
  background: #8af2c0 !important;
}

/* expense addition-deduction */
/* .MuiPaper-root.makeStyles-root-1.MuiPaper-elevation1.MuiPaper-rounded {

  box-shadow: none !important;
  border: 1px solid #ccc !important;
} */
/* certificate color picker */

input.color-picker {
  /* margin-bottom: 7px; */
  height: 24px;
  width: 24px;
  border-radius: 3px;
  /* margin-left: 12px; */
}

.card-top-border {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-success {
  border-top: 3px solid #1bc5bd !important;
  border-radius: 0px !important;
}

.card-top-border-info {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-error {
  border-top: 3px solid #ff9da7 !important;
  border-radius: 0px !important;
}

.MuiInputBase-root.search-input {
  width: 85%;
}

button.searchPlaceholder.purchaseSearch {
  right: 21px !important;
}

.purchaseSearch {
  margin-left: 20px !important;
}

.custome-border-left {
  min-width: 14%;
}

.custome-border-design {
  width: 100%;
  border-bottom: 2px solid lightgray;
  margin-left: 13px;
  margin-bottom: 10px;
}

.logo-icon {
  width: 100px;
}

/* navbar */

.aside-enabled.subheader-fixed .subheader {
  left: 255px;
}

.header-fixed.subheader-fixed .subheader {
  height: 54px;
  top: 50px !important;
  right: 0;
  transition: top 0.3s ease;
  z-index: 95;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  background-color: #fff;
  border-top: 1px solid #ebedf3;
  margin: 0;
}

.subheader {
  display: flex;
  align-items: center;
}

.dashboard-cardsection {
  padding-top: 43px !important;
}

.dashboard-navbar-left {
  width: 50% !important;
  float: left !important;
}

.dashboard-navbar-right {
  float: right !important;
  width: 47% !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.card.card-custom>.card-header {
  min-height: 45px !important;
}

.card.card-custom>.card-body {
  padding: 0.5rem 2rem !important;
}

.aside {
  /* width: 230px !important; */
}

.header-fixed.subheader-fixed .header {
  background: #3699ff;
}

.login-bg:hover {
  color: #000000 !important;
}

.top-search-bar {
  background: #0000001a;
}

.top-search-bar:hover {
  background: #007bff !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .brand {
    display: none !important;
  }
}

input.bulk-file-input {
  display: block;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.searchPlaceholder:hover {
  background: none !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

i.flaticon-search {
  margin-bottom: 0px !important;
}

.supplier-search {
  position: relative !important;
}

button.searchPlaceholder {
  position: absolute !important;
  right: 8px !important;
}

.viewIcon {
  background: #e7f3fe;
  padding: 5px;
  border-radius: 5px;
  color: #3699ff;
  font-size: 10px;
}

.custom-border {
  border-bottom: 2px solid #ecf0f3 !important;
  width: 107%;
  margin-left: -47px;
}

.supplier-table td {
  padding: 5px 0px !important;
}

i.fas.fa-trash-alt.viewIcon {
  margin-left: 15px;
}

h5.supplier-detail-date {
  font-size: 13px !important;
}

h6.supplier-detail-text {
  font-size: 11px !important;
}

h6.supplier-modal-header {
  font-weight: 900 !important;
  color: #212529 !important;
  font-size: 16px !important;
}

.supplier-info p {
  font-weight: 500 !important;
  color: #212529 !important;
  line-height: 1.5 !important;
}

.quotation-table td {
  padding: 10px 0px !important;
}

/* employee-list page */

.employee-list-input {
  height: 30px !important;
  padding: 0px !important;
}

.employee-signin {
  margin-left: -11px;
}

.MuiTab-textColorPrimary.Mui-selected {
  background: #3699ff;
  color: #fff !important;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean.focus:not(.btn-text) {
  color: #2b82dc;
  background-color: #2b82dc !important;
  border-color: transparent;
}

.chart__head {
  margin-top: 20px;
}

.custome-select>.css-yk16xz-control {
  height: auto !important;
}

.card-top-border {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-success {
  border-top: 3px solid #1bc5bd !important;
  border-radius: 0px !important;
}

.card-top-border-info {
  border-top: 3px solid #3699ff !important;
  border-radius: 0px !important;
}

.card-top-border-error {
  border-top: 3px solid #ff9da7 !important;
  border-radius: 0px !important;
}

.input-group-text {
  border-radius: 0rem;
  border-left: 0.5px solid #e5eaee;
  border-right: 0.5px solid #e5eaee;
  background: white;
}

.product-tab {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ecf0f3;
}

.product-tab ul {
  padding: 0px;
  margin: 0px;
}

.product-tab li {
  display: inline;
  background: #ecf0f3;
  color: #fff !important;
  padding: 13px 20px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-family: Poppins;
  font-weight: 500;
  border-right: 1px solid;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;
}

.product-tab li:hover,
.product-tab li.is-current {
  background: #3699ff;
}

.product-tab li:hover a,
.product-tab li.is-current a {
  color: #fff !important;
}

.product-tab li a {
  /* background: #3699ff; */
  color: #000 !important;
}

.text-help,
.text-help i {
  font-size: 10px !important;
  color: #f64e60 !important;
}

.businessPreview {
  height: 115px !important;
  width: 115px !important;
  border-radius: 50%;
}

.bannerPreview {
  width: 100% !important;
  max-height: 150px;
}

.custom_form_height {
  height: auto !important;
}

.button_disabled {
  cursor: not-allowed !important;
}

.alert_warning_bg {
  border: solid transparent;
  background: #fff3e1;
  padding: 0.6rem;
  margin: 0;
  color: #f26d3e;
  font-size: 1rem;
  line-height: 1em;
}

.btn.btn-success {
  color: #ffffff;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
}

i.fas.fa-calendar-alt.date_picker_font {
  display: contents;
}

.order-footer-details tr td {
  font-weight: bold !important;
  color: red !important;
}

textarea.form-control {
  height: auto !important;
}