.invoice-header {
    background-color: #eeeeee8a;
    color: #000;
    padding: 20px;
}

.custome-bottom-border {
    border: 1px solid #1818243d;
}

.btn-print-fixed {
    position: absolute;
    right: 35px;
    top: -55px;
}