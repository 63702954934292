@page {
    size: A4;
    margin: 0;
}

@media print {
    #print-invoice {
        width: 210mm;
        height: 297mm;
        background: initial;
        page-break-after: always;
    }
}

.print-invoice {
    $self: &;
    width: 210mm;
    min-height: 297mm;
    padding: 15mm;
    background: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: #000;

    #{$self}__top-head {
        display: flex;
        margin-bottom: 20px;
        font-weight: 500;

        & span {
            flex: 1;
        }
    }

    #{$self}__business-invoice-info {
        display: flex;
        margin-bottom: 20px;

        & > div {
            flex: 1;
            font-weight: 500;
        }

        & .left > div {
            width: 80px;
            height: auto;

            margin-bottom: 5px;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        & .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            text-align: right;
        }

        & .right > h3 {
            font-weight: 700;
        }

        & .right > div:first-of-type {
            margin: 10px 0;
        }

        & .right > div:last-child {
            width: 180px;
            height: auto;

            & img {
                width: 100%;
                height: 100%;
            }
        }
    }

    #{$self}__billing-delivery-info {
        display: flex;
        margin-bottom: 40px;

        & > div {
            flex: 1;
            font-weight: 500;
        }

        & .right > div:first-of-type {
            width: 80px;
            height: auto;

            & img {
                width: 100%;
                height: 100%;
            }
        }

        & .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            text-align: end;
        }

    }

    #{$self}__products-info {

        & table {
           border-bottom: 1px solid #ecf0f3;
           margin-bottom: 20px;
           padding-bottom: 20px;
        }

        & .invoice-product-img {
            width: 50px;
            height: auto;
        }

        & .invoice-product-img img {
            width: 100%;
            height: 100%;
        }

        & table > tr {
            & > th {
                padding-bottom: 8px;

                &:last-of-type {
                    text-align: end;
                }
            }

            & > td {
                padding-top: 20px;
                font-weight: 500 !important;

                &:last-of-type {
                    text-align: end;
                }
            }
        }
    }

    #{$self}__total {
        font-weight: 500 !important;

        & > div {
            width: 40%;
            border-top: 1px solid #ecf0f3;

            & > div {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
            }
        }
    }
}