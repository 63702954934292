#seller-register-page {
    overflow: hidden;

    .seller-sign-up-text {
        text-align: center;
        background-color: #e90110;
        color: #fff;
        padding: 10px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: -16px;
        text-transform: uppercase;
        border-radius: 15px 15px 0px 0px;
    }

    .seller-registration-card{
        box-shadow: 4px 2px 30px #37b6291c;
        border: none !important;
        padding: 25px 50px !important;
        border-radius: 0px !important;
        margin: 16px 14px;
    }

    .form-control {
        height: auto !important;
        padding: 4px 5px;
        border-radius: 5px !important;

        &:focus, &:active, &:hover{
            box-shadow: 5px 7px 15px 0px #37b6292e;
            border: 1px solid #37b629;
        }
    }
    .register_rhf_input{
        border-radius: 5px!important;
        
        &:focus, &:active, &:hover{
            box-shadow: 5px 7px 15px 0px #37b6292e;
            border: 1px solid #37b629;
        }
    }
    .btn-get-otp {
        margin-top: 25px!important;
        padding: 12px 10px;

        .fa-chevron-right {
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .btn-sign-up {
        background: #37b629 !important;
        border: 1px solid #37b629 !important;
        min-width: 200px;
        margin-top: 20px;
        font-weight: bold;
        border-radius: 20px;
    }
}

@media (min-width: 120px) and (max-width: 768px) {
    .auth-page {
        padding: 1rem!important;
    }

    #seller-register-page {
        .col-8 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .seller-registration-card{
            padding: 15px !important;
        }
    }
}