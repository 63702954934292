//
// Aside dark theme
//

// Initialization of global variables, mixins and functions
@import "../../../init";

.aside {
  background-color: get($layout-themes, dark);

  // Aside Menu
  .aside-menu {
    // Scrollbar
    @include perfect-scrollbar-theme(#637099);
  }

  .aside-menu .menu-nav {
    margin: 0;
    list-style: none;
    padding: 15px 0;
    width: 1800px;
  }
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);
