.login-page {
    $self: &;
    display: flex;
    justify-content: space-around;

    #{$self}__body-left {
        padding: 0 5rem;
        margin-top: 2rem;

        #{$self}__body-left_head {
            color: #E9000F;
            position: relative;
            margin-bottom: 3rem;
            min-height: 100px;
            
            & h2 {
                font-weight: 600;
                text-transform: capitalize;
                font-size: 1.8rem;
            }

            &::after {
                content: "";
                width: 50px;
                height: 5px;
                background-color: #4CAF50;

                position: absolute;
                top: 100%;
                left: 0%;
            }
        }

        #{$self}__body-left_steps {

        }

        #{$self}__body-left_step {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            &:first-child #{$self}__body-left_step-icon {
                background-color: #EF5350;
            }

            &:last-child #{$self}__body-left_step-icon {
                background-color: #4CAF50;
            }

            &:nth-child(2) #{$self}__body-left_step-icon {
                background-color: #2196F3;
            }

            & p {
                letter-spacing: 1px;
                font-size: 1.4rem;
                font-weight: 500;
                color: #707070;
                margin: 0;
            }
        }


        #{$self}__body-left_step-icon {
            width: 60px;
            height: 60px;
            background-color: #ddd;
            border-radius: 50%;
            margin-right: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            & div {
                width: 45%;
                height: 45%;

                & svg {
                    color: #fff;
                    width: 100%;
                    height: 100%;
                }

                & img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    #{$self}__body-right {
        padding: 0 2rem;

        #{$self}__box {
            width: fit-content;
        }
    }
}

.login {
    $self: &;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px #0000001c;
    border-radius: 5px;
    position: relative;

    & > div:not(:last-child) {
        padding: 1rem 1rem 0 1rem;
    }

    #{$self}__title {
        font-weight: 600;
        font-size: 1.8rem;
        color: #000;
    }

    #{$self}__action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & a {
            margin: 0;
            margin-right: 1rem;
            color: #000 !important;
            font-weight: 600;
            font-size: 1.1rem;
        }

        #{$self}__btn {
            border: none;
            background: #E9000F;
            color: white;
            border-radius: 50px;
            padding: 0.5rem 1.5rem;
        }
    }

    #{$self}__option {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 2rem;
        margin-bottom: -10px;

        & p {
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
        }

        #{$self}__option-icons {
            display: flex;
        }

        #{$self}__option-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            & > svg {
                width: 100%;
                height: 100%;
            }

            &:first-child {
                margin-right: 0.5rem;
                color: #2F64A3;
                padding: 2.5px;
            }

            &:last-child {
                margin-left: 0.5rem;
                color: #E9000F;
            }
        }
    }

    #{$self}__footer-polygon {
        height: 15rem;
        background: #4CAF50;
        position: absolute;
        left: 0;
        right: 0;
        clip-path: polygon(0% 0%, 100% 45%, 100% 100%, 0% 100%);
        opacity: 0.5;
    }

    #{$self}__footer {
        position: relative;
        overflow-x: hidden;
        border-radius: 5px;

        & > div {
            height: 15rem;
            background-color: #4CAF50;
            clip-path: polygon(0% 0%, 100% 45%, 100% 100%, 0% 100%);
            margin-top: 0.8rem;

            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        #{$self}__footer-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 2rem 2rem 2rem;

            #{$self}__btn-create {
                width: 80%;
                border: none;
                background: #fff;
                color: #000;
                border-radius: 2px;
                padding: 0.8rem 2rem;
                font-weight: 600;
            }

            & p {
                color: #fff;

                & span {
                    font-weight: 600;
                }
            }
        }
    }
}

.form-control {
    height: calc(1.5em + 1.3rem + 2px) !important;
}

// Mobile Device CSS
@media (min-width: 120px) and (max-width: 768px) {
    .login-page {
        $self: &;
        #{$self}__body-left {
            #{$self}__body-left_head {
                margin-bottom: 0.5rem;
                
                & h2 {
                    font-size: 1.5rem;
                    text-align: center;
                }

                &::after {
                    width: auto;
                }
            }
    
            #{$self}__body-left_step {
                & p {
                    font-size: 1rem;
                }
            }

            #{$self}__body-left_step-icon {
                width: 40px;
                height: 40px;

                & div {
                    & svg {
                        height: 20px;
                        width: 20px;
                    }
    
                    & img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        #{$self}__body-right {
            #{$self}__box {
                width: auto;
            }
        }
    }
}