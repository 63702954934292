.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #1bc5bd;
    background-color: #ffffff;
    border-bottom: 2px solid #1bc5bd !important;
    border: none;
}

.nav-tabs .nav-link {
    border: none;
}

a {
    color: #1bc5bd !important;
}