.auth-page {
    $self: &;
    background-image: url(/media/bg/109428.png);
    background-size: cover;
    padding: 4rem;
    display: flex;
    height: 100vh;

    #{$self}__content {
        border-radius: 10px;
        background-color: white;
        padding: 4rem 0;
        flex-basis: 100%;
        height: fit-content;
    }

    #{$self}__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        #{$self}__header-logo {
            width: 200px;
            height: 100px;
            font-weight: bold;
            margin-bottom: 2rem;
            display: flex;
            justify-content: center;

            & img {
                height: 100%;
            }
        }

        #{$self}__heading {
            font-weight: 600;
            font-size: 2.5rem;
            text-transform: uppercase;
            color: black;

            & span {
                font-weight: 700;
                font-size: 3rem;
                letter-spacing: 2px;
            }
        }
    }
}

// Mobile Device CSS
@media (min-width: 120px) and (max-width: 768px) {
    .auth-page {
        $self: &;
        
        #{$self}__header {
            margin-bottom: 0rem;
    
            #{$self}__heading {
                font-size: 1.5rem;
                & span {
                    font-size: 1.8rem;
                }
            }
        }
    }
}